import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function pluralise(count: number, singular: string, plural: string) {
  return count <= 1 ? singular : plural;
}

export const urlify = (value: string) => {
  return value.trim().replace(/\s/g, "%20");
};
