<template>
  <h1 class="leading-none lg:mb-8">Your details</h1>
  <section>
    <div class="grid grid-cols-6 gap-4 w-full">
      <div class="col-span-3">
        <FormField v-slot="{ componentField }" name="first_name">
          <FormItem v-auto-animate>
            <FormLabel> First Name* </FormLabel>
            <FormControl>
              <Input
                class="unmask"
                type="text"
                v-bind="componentField"
                v-model="form.first_name"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
      </div>
      <div class="col-span-3">
        <FormField v-slot="{ componentField }" name="last_name">
          <FormItem v-auto-animate>
            <FormLabel> Last Name* </FormLabel>
            <FormControl>
              <Input
                type="text"
                v-bind="componentField"
                v-model="form.last_name"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
      </div>
      <div class="col-span-6">
        <FormField v-slot="{ componentField }" name="email">
          <FormItem v-auto-animate>
            <FormLabel> Email* </FormLabel>
            <FormControl>
              <Input
                class="unmask"
                type="text"
                v-bind="componentField"
                v-model="form.email"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
      </div>
      <div class="col-span-6">
        <FormField v-slot="{ componentField }" name="phone">
          <FormItem v-auto-animate>
            <FormLabel> Phone* </FormLabel>
            <FormControl>
              <Input
                class="unmask"
                type="text"
                v-bind="componentField"
                v-model="formattedPhone"
                @input="handlePhoneInput"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
      </div>
      <div class="col-span-6">
        <FormField v-slot="{ componentField }" name="address">
          <FormItem v-auto-animate>
            <FormLabel> Address* </FormLabel>
            <FormControl>
              <Input
                type="text"
                v-bind="componentField"
                v-model="form.address"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
      </div>
      <div class="col-span-6 md:col-span-2">
        <FormField v-slot="{ componentField }" name="address_suburb">
          <FormItem v-auto-animate>
            <FormLabel> Suburb* </FormLabel>
            <FormControl>
              <Input
                disabled
                type="text"
                v-bind="componentField"
                v-model="form.address_suburb"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
      </div>
      <div class="col-span-6 md:col-span-2">
        <FormField v-slot="{ componentField }" name="address_state">
          <FormItem v-auto-animate>
            <FormLabel> State* </FormLabel>
            <FormControl>
              <Input
                disabled
                type="text"
                v-bind="componentField"
                v-model="form.address_state"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
      </div>
      <div class="col-span-6 md:col-span-2">
        <FormField v-slot="{ componentField }" name="address_postcode">
          <FormItem v-auto-animate>
            <FormLabel> Postcode* </FormLabel>
            <FormControl>
              <Input
                disabled
                type="text"
                v-bind="componentField"
                v-model="form.address_postcode"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useBookingStore } from "~/store";
import { vAutoAnimate } from "@formkit/auto-animate/vue";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { AsYouType, type CountryCode } from "libphonenumber-js";

const { form, country } = useBookingStore();
const formattedPhone = ref();

onMounted(() => {
  if (!form.phone) return;
  formattedPhone.value = formatPhoneNumber(form.phone);
});

watch(
  () => form.phone,
  (newPhone) => {
    formattedPhone.value = formatPhoneNumber(newPhone);
  }
);

function handlePhoneInput(event: Event) {
  const input = event.target as HTMLInputElement;
  const formatter = new AsYouType(country.toUpperCase() as CountryCode);
  formattedPhone.value = formatter.input(input.value);
  form.phone = formatter.getNumberValue() || "";
}

function formatPhoneNumber(value: string): string {
  const formatter = new AsYouType(country.toUpperCase() as CountryCode);
  return formatter.input(value);
}
</script>
