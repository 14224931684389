<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import {
  ProgressIndicator,
  ProgressRoot,
  type ProgressRootProps,
} from "radix-vue";
import { cn } from "@/lib/utils";

const props = withDefaults(
  defineProps<ProgressRootProps & { class?: HTMLAttributes["class"] }>(),
  {
    modelValue: 0,
  }
);

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;
  return delegated;
});
</script>

<template>
  <div class="relative">
    <ProgressRoot
      v-bind="delegatedProps"
      :class="cn('relative h-4 overflow-hidden rounded-full', props.class)"
      :style="{
        background: 'hsla(19, 90%, 96%, 1)',
      }"
    >
      <ProgressIndicator
        class="h-full flex-1 bg-primary transition-all"
        :style="`transform: translateX(-${100 - (props.modelValue ?? 0)}%);`"
      />
    </ProgressRoot>
    <div
      class="w-2 h-2 bg-primary absolute top-[-2px] z-[51] rounded-full transition-all"
      :style="{
        left: `calc(${props.modelValue}% - 4px)`,
        display: 'block',
      }"
    />
  </div>
</template>
