export const REGULAR = "Regular";
export const ONEOFF = "Oneoff";
export const CATCHUP = "Catchup";
export const WINDOW_CLEANING = "Window Cleaning";
export const ONEOFF_TYPE_BOND = "Bond";
export const ONEOFF_TYPE_MOVE_IN = "Move In";
export const ONEOFF_TYPE_SPRING = "Spring";
export const FREQUENCY_WEEKLY = "Weekly";
export const FREQUENCY_FORTNIGHTLY = "Fortnightly";
export const PAYMENT_METHOD_CREDIT_CARD = "Credit Card";
export const PAYMENT_METHOD_CASH = "Cash";
export const STRIPE_PERCENTAGE = 1.2;
export const STRIPE_FIXED = 0.27;
