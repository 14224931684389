import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoAmex, LazySvgoAppstore, LazySvgoBank, LazySvgoCreditCard, LazySvgoGoogleplay, LazySvgoInformationSquare, LazySvgoLogoMinimal, LazySvgoLogo, LazySvgoMastercard, LazySvgoPoweredByStripe, LazySvgoVisa } from '#components'
const lazyGlobalComponents = [
  ["SvgoAmex", LazySvgoAmex],
["SvgoAppstore", LazySvgoAppstore],
["SvgoBank", LazySvgoBank],
["SvgoCreditCard", LazySvgoCreditCard],
["SvgoGoogleplay", LazySvgoGoogleplay],
["SvgoInformationSquare", LazySvgoInformationSquare],
["SvgoLogoMinimal", LazySvgoLogoMinimal],
["SvgoLogo", LazySvgoLogo],
["SvgoMastercard", LazySvgoMastercard],
["SvgoPoweredByStripe", LazySvgoPoweredByStripe],
["SvgoVisa", LazySvgoVisa],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
