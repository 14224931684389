import {defineNuxtPlugin} from '#app';

export default defineNuxtPlugin(() => {
	if (window.location.hostname.endsWith('.co.nz')) {
		(function (c, l, a, r, i, t, y) {
			c[a] =
				c[a] ||
				function () {
					(c[a].q = c[a].q || []).push(arguments);
				};
			t = l.createElement(r);
			t.async = 1;
			t.src = 'https://www.clarity.ms/tag/' + i;
			y = l.getElementsByTagName(r)[0];
			y.parentNode.insertBefore(t, y);
		})(window, document, 'clarity', 'script', 'p3utiytcm0');
	}
});
