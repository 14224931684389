<script setup lang="ts">
import { ONEOFF, REGULAR, FREQUENCY_FORTNIGHTLY } from "~/lib/constants";
import { pluralise } from "~/lib/utils";
import { useBookingStore } from "~/store";
import InformationSquare from "~/assets/svg/information-square.svg";
import { vAutoAnimate } from "@formkit/auto-animate";

const { form, getBaseRate, getMinimumHours, getHourlyRate, usingCreditCard, getProcessingFee, isRegular, getTotal } = useBookingStore();
</script>

<template>
  <div class="lg:border border-primary/30 lg:rounded-xl">
    <div class="p-6 lg:p-8">
      <h2 class="leading-none border-b pb-6 text-lg lg:text-xl">
        Booking Summary
      </h2>
      <div
        class="grid xl:grid-cols-2 lg:grid-cols-2 grid-cols-2 gap-4 mt-8 gap-y-4"
      >
        <h4>Location</h4>
        <p class="text-right font-medium">
          <template v-if="form.suburb">
            {{ form.suburb.name }}
            ({{ form.suburb.postcode }})
          </template>
          <template v-else> -- </template>
        </p>
        <h4>Clean Type</h4>
        <p class="text-right font-medium">
          <template v-if="form.service === REGULAR">
            Regular
            <template v-if="form.frequency">
              <template v-if="form.frequency === FREQUENCY_FORTNIGHTLY">
                (Fortnightly)
              </template>
              <template v-else-if="form.days">
                ({{ form.days }}
                {{ pluralise(form.days, "day", "days") }} per week)
              </template>
              <template v-else> ({{ form.frequency }}) </template>
            </template>
          </template>
          <template v-else-if="form.service === ONEOFF">
            One-off
            <template v-if="form.oneoff_type">
              <br />
              ({{ form.oneoff_type }})
            </template>
          </template>
          <template v-else> -- </template>
        </p>
        <div
          class="col-span-2 grid xl:grid-cols-2 lg:grid-cols-2 grid-cols-2 gap-4 gap-y-0"
        >
          <h4>Hours</h4>
          <p class="text-right font-medium">
            <template v-if="form.hours">
              {{ form.hours }} hours
            </template>
            <template v-else> -- </template>
          </p>
          <p class="col-span-2 font-light text-right">
            <template v-if="form.hours">
              ${{ getBaseRate(form.service) }} for
              {{ getMinimumHours(form.service) }}
              {{
                pluralise(
                  getMinimumHours(form.service),
                  "hour",
                  "hours",
                )
              }}
            </template>
          </p>
          <p class="col-span-2 font-light text-right">
            <template v-if="form.hours">
              ${{ getHourlyRate(form.service) }}/hour thereafter
            </template>
          </p>
        </div>
      </div>
    </div>
    <div
      class="bg-primary-light dark:bg-primary/30 dark:text-primary px-6 lg:px-8 py-6 lg:py-4 lg:rounded-b-2xl"
    >
      <h5>Booking Total</h5>
      <h1 v-auto-animate>
        ${{ getTotal() }}
        <span class="text-md font-light">per service</span>
      </h1>
      <small class="block font-light" v-if="usingCreditCard()">
        *Service fee of ${{ getProcessingFee() }}
      </small>
      <div
        class="border border-primary-soft bg-white rounded-xl p-8 mt-4 lg:hidden"
        v-if="isRegular()"
      >
        <div class="flex items-start gap-x-4">
          <div class="relative">
            <InformationSquare
              class="w-6 h-6 text-primary relative"
              filled
              :fontControlled="false"
            />
          </div>
          <p class="font-light">
            Equipment and products need to be provided by the household
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    class="border border-primary/30 rounded-xl p-8 mt-8 hidden lg:block"
    v-if="isRegular()"
  >
    <div class="flex items-start gap-x-4">
      <div class="relative">
        <InformationSquare
          class="w-6 h-6 text-primary relative"
          filled
          :fontControlled="false"
        />
      </div>
      <p class="font-light">
        Equipment and products need to be provided by the household
      </p>
    </div>
  </div>
</template>
