<script setup lang="ts">
import { Loader2 } from "lucide-vue-next";
import { useBookingStore } from "~/store";
import { captureException } from "@sentry/vue";

const { form, nextStep, previousStep } = useBookingStore();

const buttonText = computed(() => {
  if (form.step === 5) {
    return "Submit";
  }

  return "Next";
});

const next = () => {
  const HTMLForm: HTMLFormElement | null = document.getElementById(
    "form"
  ) as HTMLFormElement | null;
  if (HTMLForm) {
    if (typeof HTMLForm.requestSubmit === "function") {
      HTMLForm.requestSubmit();
    } else {
      HTMLForm.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  } else {
    captureException(new Error("Form not found"));
  }
};
</script>

<template>
  <div class="flex items-center gap-x-3">
    <Button
      id="back"
      v-if="form.step > 1"
      tabindex="-1"
      variant="outline"
      size="lg"
      @click.prevent="previousStep"
      class="w-full border-border"
    >
      Back
    </Button>
    <Button
      v-if="form.step !== 1"
      id="next"
      type="submit"
      @click.prevent="next"
      size="lg"
      class="w-full"
      :disabled="form.submitting"
    >
      <Loader2 class="w-4 h-4 mr-2 animate-spin" v-show="form.submitting" />
      {{ buttonText }}
    </Button>
  </div>
</template>
